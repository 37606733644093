import React from 'react';
import Grid from '@mui/material/Grid';
import MasterPage from '../components/MasterPage';
import PlaydioCard from '../components/cards/PlaydioCard';
import SortiDuChapeauCard from '../components/cards/SortiDuChapeauCard';

export default function PersonalWork() {
  return (
    <MasterPage topBarVersion="mini">
      <Grid item><PlaydioCard /></Grid>
      <Grid item><SortiDuChapeauCard /></Grid>
    </MasterPage>
  );
}
