import React from 'react';
import CardItem from '../CardItem';

export default function MorningnewsCard() {
  return (
    <CardItem
      title="Morningnews"
      subtitle="Actualités et liste de souhaits"
      description="Crée-toi un compte, visualise les articles de différents journaux, et ajoute-les à ta liste de souhaits.
        Change la langue de français à anglais."
      mediaFile={require("../../media/morningnews-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/lacapsule-morningnews"
      websiteUrl="https://lacapsule-morningnews.netlify.app"
    />
  )
}
