import React from 'react';
import CardItem from '../CardItem';

export default function FaceupCard() {
  return (
    <CardItem
      title="Faceup"
      subtitle="Photos, reconnaissance faciale"
      description="Prends des photos de tes amis et toi, et affiche ces photos dans ta galerie. La galerie génèrera automatiquement
        des informations à partir des visages grâce à l’intelligence artificielle : sexe, âge, lunettes ou non, barbe ou non, etc…"
      mediaFile={require("../../media/faceup-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubFrontendPath="MarionToutant/lacapsule-faceup-frontend"
      githubBackendPath="MarionToutant/lacapsule-faceup-backend"
      downloadUrl="https://tout-en-m.net/applications_mobiles/faceup.apk"
    />
  )
}
