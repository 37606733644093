import React from 'react';
import CardItem from '../CardItem';

export default function BikeshopCard() {
  return (
    <CardItem
      title="Bikeshop"
      subtitle="Vente de vélos"
      description="Visualise les vélos en vente, ajoute-les à ton panier, indique ton mode de livraison et règle ton panier"
      mediaFile={require("../../media/bikeshop-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/lacapsule-bikeshop"
      websiteUrl="https://lacapsule-bikeshop.netlify.app"
    />
  )
}
