import React from 'react';
import Grid from '@mui/material/Grid';
import MasterPage from '../components/MasterPage';
import NetmathCard from '../components/cards/NetmathCard';

export default function Scolab() {
  return (
    <MasterPage topBarVersion="mini">
      <Grid item><NetmathCard /></Grid>
    </MasterPage>
  );
}
