import React from 'react';
import CardItem from '../CardItem';

export default function NetmathCard() {
  return (
    <CardItem
      title="Netmath"
      subtitle="Apprentissage des maths"
      description="Netmath, la plateforme éducative où tous les élèves ont du plaisir à apprendre !"
      demoLink="https://youtu.be/3qG-zRLUyNI"
      mediaFile={require("../../media/netmath-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="scolab"
      websiteUrl="https://netmath.ca/fr"
    />
  )
}
