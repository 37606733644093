import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NetmathCard from '../components/cards/NetmathCard';
import PlaydioCard from '../components/cards/PlaydioCard';
import SortiDuChapeauCard from '../components/cards/SortiDuChapeauCard';
import WeatherappCard from '../components/cards/WeatherappCard';
import MyMovizCard from '../components/cards/MyMovizCard';
import MorningnewsCard from '../components/cards/MorningnewsCard';
import BlackboardCard from '../components/cards/BlackboardCard';
import TicketacCard from '../components/cards/TicketacCard';
import BikeshopCard from '../components/cards/BikeshopCard';
import MyMailsCard from '../components/cards/MyMailsCard';
import FaceupCard from '../components/cards/FaceupCard';
import LocapicCard from '../components/cards/LocapicCard';
import MasterPage from '../components/MasterPage';

export default function Home() {
  return (
    <MasterPage topBarVersion="full">
      <Grid item paddingRight="50px" xs={12}>
        <Typography variant="h6" color="text.secondary" fontStyle="italic">
          Voici le portfolio de mes sites web et applications mobiles, développés en Javascript, ReactJS, React Native et NodeJS &#x1F603;
          <br /> Bonne visite !
        </Typography>
      </Grid>
      <Grid item><NetmathCard /></Grid>
      <Grid item><PlaydioCard /></Grid>
      <Grid item><SortiDuChapeauCard /></Grid>
      <Grid item><WeatherappCard /></Grid>
      <Grid item><MyMovizCard /></Grid>
      <Grid item><MorningnewsCard /></Grid>
      <Grid item><BlackboardCard /></Grid>
      <Grid item><TicketacCard /></Grid>
      <Grid item><BikeshopCard /></Grid>
      <Grid item><MyMailsCard /></Grid>
      <Grid item><FaceupCard /></Grid>
      <Grid item><LocapicCard /></Grid>
    </MasterPage>
  );
}
