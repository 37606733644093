import React from 'react';
import CardItem from '../CardItem';

export default function TicketacCard() {
  return (
    <CardItem
      title="Tickectac"
      subtitle="Réservation de train"
      description="Cherche un horaire de train, sélectionne-le et mets-le dans ton panier pour plus tard"
      mediaFile={require("../../media/ticketac-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/lacapsule-ticketac"
      websiteUrl="https://lacapsule-ticketac.netlify.app"
    />
  )
}
