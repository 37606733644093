import React from 'react';
import CardItem from '../CardItem';

export default function MyMailsCard() {
  return (
    <CardItem
      title="MyMails"
      subtitle="Clavardage simple"
      description="Parle en direct !"
      mediaFile={require("../../media/mymails-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/lacapsule-mymails"
      websiteUrl="https://lacapsule-mymails.netlify.app"
    />
  )
}
