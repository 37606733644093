import React from 'react';
import CardItem from '../CardItem';

export default function LocapicCard() {
  return (
    <CardItem
      title="Locapic"
      subtitle="Carte touristique et chat"
      description="En tant que touriste, utilise la carte intégrée pour épingler tes points d’intérêts, et sauvegarde-les.
        Vois les autres touristes sur la carte, et chatte avec eux !"
      mediaFile={require("../../media/locapic-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubFrontendPath="MarionToutant/lacapsule-locapic-frontend"
      githubBackendPath="MarionToutant/lacapsule-locapic-backend"
      downloadUrl="https://tout-en-m.net/applications_mobiles/locapic.apk"
    />
  )
}
