import React from 'react';
import CardItem from '../CardItem';

export default function PlaydioCard() {
  return (
    <CardItem
      title="Playdio"
      subtitle="Playlists et Blind-test"
      description="A partir de ton compte Spotify, crée des playlists, et lis les trente première secondes de toutes tes musiques.
        Tu pourras ainsi organiser des soirées Blind-test !"
      demoLink="https://youtu.be/EozaXTm2C8o"
      mediaFile={require("../../media/playdio-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubFrontendPath="lacapsule-playdio/playdio-frontend"
      githubBackendPath="lacapsule-playdio/playdio-backend"
      downloadUrl="https://tout-en-m.net/applications_mobiles/playdio.apk"
    />
  )
}
