import React from 'react';
import Grid from '@mui/material/Grid';
import MasterPage from '../components/MasterPage';
import WeatherappCard from '../components/cards/WeatherappCard';
import MyMovizCard from '../components/cards/MyMovizCard';
import MorningnewsCard from '../components/cards/MorningnewsCard';
import BlackboardCard from '../components/cards/BlackboardCard';
import TicketacCard from '../components/cards/TicketacCard';
import BikeshopCard from '../components/cards/BikeshopCard';
import MyMailsCard from '../components/cards/MyMailsCard';
import FaceupCard from '../components/cards/FaceupCard';
import LocapicCard from '../components/cards/LocapicCard';

export default function StudyWork() {
  return (
    <MasterPage topBarVersion="mini">
      <Grid item><WeatherappCard /></Grid>
      <Grid item><MyMovizCard /></Grid>
      <Grid item><MorningnewsCard /></Grid>
      <Grid item><BlackboardCard /></Grid>
      <Grid item><TicketacCard /></Grid>
      <Grid item><BikeshopCard /></Grid>
      <Grid item><MyMailsCard /></Grid>
      <Grid item><FaceupCard /></Grid>
      <Grid item><LocapicCard /></Grid>
    </MasterPage>
  );
}
