import React from 'react';
import CardItem from '../CardItem';

export default function BlackboardCard() {
  return (
    <CardItem
      title="Blackboard"
      subtitle="Back-office vente de trottinettes "
      description="Visualise ton tableau de bord, tes clients, ton catalogue de produits, tes commandes,
        et étudie les graphiques liés à la vente de trottinettes"
      mediaFile={require("../../media/blackboard-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/lacapsule-blackboard"
      websiteUrl="https://lacapsule-blackboard.netlify.app"
    />
  )
}
