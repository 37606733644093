import React from 'react';
import CardItem from '../CardItem';

export default function MyMovizCard() {
  return (
    <CardItem
      title="MyMoviz"
      subtitle="Films et liste de souhaits"
      description="Visualise les films à l’affiche en ce moment, ajoute un like, un avis, indique si tu les as regardés,
        et ajoute-les à ta liste de souhaits"
      mediaFile={require("../../media/mymoviz-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/lacapsule-mymoviz"
      websiteUrl="https://lacapsule-mymoviz.netlify.app"
    />
  )
}
