import React from 'react';
import CardItem from '../CardItem';

export default function WeatherappCard() {
  return (
    <CardItem
      title="Weatherapp"
      subtitle="Météo et carte"
      description="Crée-toi un compte, et cherche la météo d’une ville de ton choix.
        Elle s’affichera sur la carte, et se sauvegardera dans tes recherches !"
      mediaFile={require("../../media/weatherapp-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/lacapsule-weatherapp"
      websiteUrl="https://lacapsule-weatherapp.netlify.app"
    />
  )
}
