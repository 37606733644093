import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Home from './pages/Home';
import Scolab from './pages/Scolab';
import StudyWork from './pages/StudyWork';
import PersonalWork from './pages/PersonalWork';
import { theme } from './styles/Theme';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/scolab" element={<Scolab />} />
          <Route path="/study-work" element={<StudyWork />} />
          <Route path="/personal-work" element={<PersonalWork />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
