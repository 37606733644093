import React from 'react';
import MenuElement from '../components/MenuElement';

interface IMenuPageElementsProps {
  readonly pathName: string;
}

export default function MenuPageElements({ pathName }: IMenuPageElementsProps) {
  return (
    <>
      <MenuElement
        isHome={false}
        title="Scolab"
        navigationPath="/scolab"
        isSelected={pathName === "/scolab"}
        logoFile="scolab-logo-192.png"
        logoAlt="Logo Scolab"
      />
      <MenuElement
        isHome={false}
        title="Travaux d'école"
        navigationPath="/study-work"
        isSelected={pathName === "/study-work"}
        logoFile="la-capsule-logo-192.png"
        logoAlt="Logo La Capsule"
      />
      <MenuElement
        isHome={false}
        title="Travaux personnels"
        navigationPath="/personal-work"
        isSelected={pathName === "/personal-work"}
        logoFile="tout-en-m-logo-192.png"
        logoAlt="Logo Tout-En-M"
      />
    </>
  )
}
