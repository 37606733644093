import React from 'react';
import CardItem from '../CardItem';

export default function SortiDuChapeauCard() {
  return (
    <CardItem
      title="Sorti du Chapeau"
      subtitle="Tirage au sort"
      description="Entre le nom des participants ainsi que leur adresse email, et envoie à chacun le nom de la personne qui lui a été affectée !
        Utile pour Noël surprise, jeux de Killer…"
      mediaFile={require("../../media/sorti-du-chapeau-banner.png")}
      mediaType="img"
      mediaHeight={140}
      githubPath="MarionToutant/sorti-du-chapeau"
      websiteUrl="https://sorti-du-chapeau.netlify.app"
    />
  )
}
